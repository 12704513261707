<template>
  <div ref="dishList">
    <BaseList
      type="dish"
      :pagination="false"
      :searchTerm.sync="search"
      :noData="dishCount === 0"
      :isLoading="dishCount === null"
      noDataText="dish.no-dish-found"
      :filterCount="filterCount"
      @doSearch="findByKeyword"
      :fullScreen="fullScreen"
    >
      <template v-slot:filter1>
        <BaseFilter
          v-if="edit || container !== 'menu'"
          label="dish.category-dropdown"
          no-data="dish.no-category"
          v-model="selectedCategory"
          :items="dishCategories"
          :item-text="$i18n.locale === 'vi' ? 'viName' : 'enName'"
          item-value="categoryCode"
        />
      </template>
      <template v-if="edit" v-slot:filter2>
        <BaseFilter
          label="dish.menu-visibility-status"
          no-data="dish.no-status"
          v-model="serving"
          :items="status"
          item-text="status"
          item-value="key"
        />
      </template>
      <v-alert
        v-if="runCampaign && $auth.isAuthenticated"
        class="my-3"
        dense
        text
        border="left"
        type="warning"
      >
        {{ $t('common.run-campaign') }}
      </v-alert>
      <v-row v-for="(chunk, index1) in chunkedList" :key="index1">
        <v-col
          v-for="(dish, index2) in chunk"
          :key="index2"
          :cols="12 / chunkSize"
          :sm="12 / chunkSize"
          :md="12 / chunkSize"
          :lg="12 / chunkSize"
        >
          <DishListItem
            :dish="dish"
            :alternativeColor="alternativeColor(index1)"
            :edit="edit"
            :container="container"
            @openDishOptions="openDishOptionsDialog(dish)"
          />
        </v-col>
      </v-row>
    </BaseList>
    <DishOptions
      v-if="dishOptionsDialog"
      :dialog.sync="dishOptionsDialog"
      :dish="selectedDish"
      :edit="edit"
      :container="container"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DishListItem from './DishListItem'
import { fullTextSearch } from '@/utils'
import chunk from 'lodash/chunk'

const DishOptions = () => import('@/components/dishes/DishOptions')

export default {
  name: 'DishList',

  components: {
    DishListItem,
    DishOptions
  },

  props: {
    edit: {
      type: Boolean,
      default: false
    },
    container: {
      validator: function(value) {
        return ['order-dialog', 'invoice-dialog', 'menu'].includes(value)
      },
      default: 'menu'
    },
    fullScreen: {
      type: Boolean,
      default: true
    },
    searchTerm: String,
    category: String
  },

  data: () => ({
    search: '',
    selectedCategory: null,
    searchQuery: '',
    serving: null,
    containerSize: 0,
    dishOptionsDialog: false,
    editOption: null,
    selectedDish: null
  }),

  computed: {
    ...mapState('dish', ['dishCategories', 'dishes']),
    ...mapState('common', ['runCampaign']),

    status() {
      return [
        { status: this.$t('common.visible'), key: true },
        { status: this.$t('common.hidden'), key: false }
      ]
    },

    filterCount() {
      if (this.edit) {
        return 2
      } else if (this.container !== 'menu') {
        return 1
      } else {
        return null
      }
    },

    dishCount() {
      if (Array.isArray(this.filteredList)) {
        return this.filteredList.length
      } else {
        return null
      }
    },

    filteredList() {
      if (this.dishes) {
        return this.dishes.filter(dish => {
          const { viName, enName, description } = dish

          const matchText = fullTextSearch(this.searchQuery, [
            viName,
            enName,
            description
          ])

          const matchCategory =
            !this.selectedCategory || dish.category === this.selectedCategory

          const matchServing =
            this.serving === undefined ||
            this.serving === null ||
            dish.serving === this.serving

          if (matchText && matchCategory && matchServing) {
            return true
          } else {
            return false
          }
        })
      } else {
        return null
      }
    },

    chunkSize() {
      const { xsOnly, smOnly, lgAndUp } = this.$vuetify.breakpoint
      if (xsOnly) {
        return 1
      } else if (smOnly) {
        return 2
      } else if (this.fullScreen) {
        if (lgAndUp && this.containerSize > 1000) {
          return 4
        } else {
          return 3
        }
      } else {
        return 2
      }
    },

    chunkedList() {
      return chunk(this.filteredList, this.chunkSize)
    }
  },

  mounted() {
    this.getContainerSize()
    this.$mitt.on('toggleDrawer', this.changeView)
  },
  beforeDestroy() {
    this.$mitt.off('toggleDrawer', this.changeView)
  },

  watch: {
    edit: {
      immediate: true,
      handler: function(val) {
        if (val === false) {
          this.serving = true
        }
      }
    },

    $route: {
      immediate: true,
      handler: function() {
        if (this.category !== 'all' && this.category !== 'any') {
          this.selectedCategory = this.category
        }
        this.searchQuery = this.searchTerm
        this.search = this.searchTerm
      }
    }
  },

  methods: {
    openDishOptionsDialog(dish) {
      this.selectedDish = dish
      this.dishOptionsDialog = true
    },

    changeView() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.getContainerSize()
      }
    },

    getContainerSize() {
      this.containerSize = this.$refs.dishList.clientWidth
    },

    findByKeyword() {
      if (
        this.container === 'menu' &&
        !this.edit &&
        this.$route.path !== '/dishes/all'
      ) {
        this.redirectSearch()
      } else if (
        this.$route.path === '/dishes/all' &&
        this.search !== this.$route.query.search
      ) {
        this.$router.push({ query: { search: this.search } })
      } else {
        this.searchDish()
      }
      if (this.container === 'menu') {
        this.trackSearch()
      }
    },

    redirectSearch() {
      this.$router.push({
        name: 'dish-category',
        params: { category: 'all' },
        query: { search: this.search }
      })
    },

    searchDish() {
      this.searchQuery = this.search
      this.selectedCategory = null
      this.serving = null
      this.$helper.scrollTop()
    },

    alternativeColor(index1) {
      if (index1 % 2 === 0) {
        return false
      } else {
        return true
      }
    },

    trackSearch() {
      this.$gtag.event('search', { search_term: this.search })
    }
  }
}
</script>

<style scoped></style>

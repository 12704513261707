<template>
  <v-card :color="cardColor" elevation="8" @click.stop="selectDish">
    <v-card-actions v-if="$auth.isAuthenticated && edit">
      <BaseIconButton icon-name="delete" @action="openDeleteDish" />
      <v-spacer />
      <BaseIconButton icon-name="pencil" @action="openEditDish" />
    </v-card-actions>
    <div :class="flexClass">
      <div v-if="showDishImage && cardBreakpoint" class="image-container">
        <v-img
          class="flex-image"
          :src="dish.imgUrl || require('@/assets/food.jpeg')"
          @error="dish.imgUrl = require('@/assets/food.jpeg')"
        />
      </div>
      <v-avatar v-else-if="showDishImage" class="ml-4" size="72">
        <v-img
          :src="dish.imgUrl || require('@/assets/food.jpeg')"
          @error="dish.imgUrl = require('@/assets/food.jpeg')"
        />
      </v-avatar>
      <div :class="textClass">
        <v-card-title :class="titleText">{{ dish.viName }}</v-card-title>
        <v-card-subtitle :class="subtitleText">
          {{ dish.enName }}
        </v-card-subtitle>
        <v-spacer />
        <v-card-text :class="priceText">
          <div v-if="dish.priceTBD" class="text-capitalize">
            {{ $t('dish.price-TBD') }}
          </div>
          <div v-else>
            <span>{{ lowestPrice.toLocaleString() }}</span>
            <span v-if="lowestPrice !== highestPrice">
              - {{ highestPrice.toLocaleString() }}</span
            >
          </div>
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DishListItem',

  props: {
    edit: {
      type: Boolean,
      default: false
    },
    dish: Object,
    alternativeColor: Boolean,
    container: {
      validator: function(value) {
        return ['order-dialog', 'invoice-dialog', 'menu'].includes(value)
      },
      default: 'menu'
    }
  },

  computed: {
    ...mapState('common', ['showDishImage']),

    cardBreakpoint() {
      if (this.container === 'menu') {
        return this.$vuetify.breakpoint.xlOnly
      } else {
        return false
      }
    },

    compactView() {
      return this.showDishImage || this.$vuetify.breakpoint.smAndDown
    },

    flexClass() {
      const baseStyle = 'd-flex align-center justify-start'
      if (this.cardBreakpoint) {
        return `${baseStyle} flex-column`
      } else {
        return baseStyle
      }
    },

    textClass() {
      const baseStyle = 'align-self-start'
      if (this.cardBreakpoint) {
        return baseStyle
      } else {
        return `${baseStyle} py-2`
      }
    },

    cardColor() {
      if (this.dish.serving === false) {
        return 'grey lighten-2'
      } else if (this.alternativeColor) {
        return 'purple lighten-4'
      } else {
        return 'amber lighten-4'
      }
    },

    titleText() {
      const baseStyle = 'font-weight-bold text-capitalize'
      const miniStyle = 'pt-0'
      let extraStyle
      if (this.cardBreakpoint) {
        extraStyle = 'text-h5'
      } else if (this.compactView) {
        extraStyle = `text-subtitle-1 ${miniStyle}`
      } else {
        extraStyle = `text-h6 ${miniStyle}`
      }
      return `${baseStyle} ${extraStyle}`
    },

    subtitleText() {
      const baseStyle = 'font-weight-medium pb-0'
      let extraStyle
      if (this.cardBreakpoint) {
        extraStyle = 'text-h6'
      } else if (this.compactView) {
        extraStyle = 'text-subtitle-2'
      } else {
        extraStyle = 'text-subtitle-1'
      }
      return `${baseStyle} ${extraStyle}`
    },

    priceText() {
      const baseStyle = 'font-weight-bold red--text'
      const miniStyle = 'pb-0 pt-2'
      let extraStyle
      if (this.cardBreakpoint) {
        extraStyle = 'text-h5'
      } else if (this.compactView) {
        extraStyle = `text-subtitle-1 ${miniStyle}`
      } else {
        extraStyle = `text-h6 ${miniStyle}`
      }
      return `${baseStyle} ${extraStyle}`
    },

    options() {
      return [...this.dish.options]
    },

    optionNum() {
      return this.options.length
    },

    sortedOptions() {
      return this.options.map(x => x.price)
    },

    lowestPrice() {
      return Math.min(...this.sortedOptions)
    },

    highestPrice() {
      return Math.max(...this.sortedOptions)
    }
  },

  methods: {
    ...mapActions('order', ['addToBasket']),

    openEditDish() {
      this.$mitt.emit('openEditDish', this.dish)
    },

    openDeleteDish() {
      const item = {
        type: 'deleteDish',
        data: this.dish,
        confirmTitle: 'common.delete-dialog-title',
        confirmContent: this.$t('common.delete-dialog-text', {
          item: this.dish.viName
        })
      }
      this.$mitt.emit('openConfirmDialog', item)
    },

    selectDish() {
      if (!this.edit && (this.dish.priceTBD || this.options.length === 1)) {
        const selected = this.options[0]
        selected.inKilogram = this.dish.inKilogram
        selected.fromKitchen = this.dish.fromKitchen
        if (this.container === 'invoice-dialog') {
          this.$mitt.emit('addToInvoice', selected)
        } else {
          this.addToBasket(selected)
        }
        this.$notify(this.$t('order.message-add-to-order'))
      } else {
        this.$emit('openDishOptions')
      }
    }
  }
}
</script>

<style scoped>
.image-container {
  width: 100%;
  height: 220px;
  overflow: hidden;
}

.flex-image {
  height: 220px;
  width: 100%;
}
</style>
